$body-color:                  #575656 !default;
$font-family-sans-serif:      'Open Sans', sans-serif !default;
$primary:                     $red !default;
$secondary:                   $gray !default;
$accent:                      $amber !default;
$include-column-box-sizing:   true !default;

// scss-docs-start container-max-widths
$container-max-widths: (
  sm: 540px,
  md: 100%,
  lg: 100%,
  xl: 1140px,
  xxl: 1140px
) !default;
// scss-docs-end container-max-widths

// WordPress, Elementor
// $fa-font-path:         "https://www3.merzljak.de/wp-content/plugins/elementor/assets/lib/font-awesome/webfonts" !default;
// $fa-font-path:         "https://cdn2.hubspot.net/hub/2567029/hub_generated/template_assets/124276331572/1689156841982/Themes/monterey/assets/webfonts/fa5" !default;

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`

$h1-fontsize-blog:                $font-size-base * 2.3 !default;
$h2-fontsize-blog:                $font-size-base * 1.8 !default;
$h3-fontsize-blog:                $font-size-base * 1.5 !default;
$h4-fontsize-blog:                $font-size-base * 1.25 !default;
$h5-fontsize-blog:                $font-size-base * 1.1 !default;
$h6-fontsize-blog:                $font-size-base !default;