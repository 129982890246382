//
// Blog Headings
//
.single-post {
    h1 {
        @include font-size($h1-fontsize-blog);
        font-weight: 600;
    }
    
    h2 {
        @include font-size($h2-fontsize-blog);
    }
    
    h3 {
        @include font-size($h3-fontsize-blog);
    }
    
    h4 {
        @include font-size($h4-fontsize-blog);
    }
    
    h5 {
        @include font-size($h5-fontsize-blog);
    }
    
    h6 {
        @include font-size($h6-fontsize-blog);
    }

    .recent-blog-posts, .blog-tags {
        ul > li {
            font-size: .9rem;
        }
    }

}