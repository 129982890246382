// HubSpot
html, body {
  max-width: 100%;
  overflow-x: clip;
}

body {
  margin-top:64px;
  @include media-breakpoint-up(lg) {
    margin-top: 78px;
  }
}

.body-container {
  &.container-fluid {
    padding-left: 0;
    padding-right: 0;
  }
}

// Header
#header {
  background-color: #fff;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
     -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
          box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  .navbar-brand {
    .hs-image-widget {
      max-width: 100px;
      @include media-breakpoint-up(lg) {
        max-width: 190px;
      }
      -webkit-transition: max-width .5s ease;
      transition: max-width .5s ease;
    }
  }
  .navbar-toggler {
    &:hover {
      background-color: $white;
    }
  }
  &.fixed-top {
    .navbar-brand {
      .hs-image-widget {
        @include media-breakpoint-up(lg) {
          max-width: 140px;
        }
      }
    }
  }
}
        
.navigation-primary {
  .navbar-nav {
    .nav-item {
      .nav-link {
        white-space: nowrap;
        &:hover {
          color: $primary;
        }
      }
    }
    &.level-1 {
      > .nav-item {
        &.dropdown:hover {
          > .dropdown-menu {
            display: block;
          }
        }
        > .nav-link {
          text-transform: uppercase;
          @include media-breakpoint-up(lg) {
            padding-left: 20px;
            padding-right: 20px;
          }
        }
      }
    }
    .level-2 {
      background-color: $white;
      padding-top: 0;
      padding-bottom: 0;
      @include media-breakpoint-down(lg) {
        border: 0;
        margin-bottom: 1rem;
      }
      @include box-shadow($box-shadow-lg);
      @include border-radius(0);
      @include media-breakpoint-down(lg) {
        > .nav-item {
          > .nav-link {
            padding-left: 1rem;
            padding-top: .2rem;
            padding-bottom: .2rem;
          }
        }
      }
      @include media-breakpoint-up(lg) {
        > .nav-item {
          border-bottom: 1px solid $gray-300;
          &:last-child {
            border-bottom: 0;
          }
          > .nav-link {
            padding-left: 1rem;
            padding-right: 1rem;
          }
        }
      }
    }
  }
}

//scroll to top
.eael-ext-scroll-to-top-wrap.scroll-to-top-hide {
  display:none
 }
 .eael-ext-scroll-to-top-button {
  position:fixed;
  cursor:pointer;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center
 }

 .eael-ext-scroll-to-top-wrap .eael-ext-scroll-to-top-button {
  bottom: 15px;
  right: 31px;
  width: 54px;
  height: 55px;
  z-index: 9999;
  opacity: 1;
  background-color: var( --e-global-color-4265a15 );
  border-radius: 35px;
}

.eael-ext-scroll-to-top-wrap .eael-ext-scroll-to-top-button i {
  color: #ffffff;
  font-size: 20px;
}

.eael-ext-scroll-to-top-wrap .eael-ext-scroll-to-top-button svg {
  fill: #ffffff;
  width: 32px;
  height: 32px;
} 
.eael-ext-scroll-to-top-wrap.scroll-to-top-hide {
  display:none
 }
 .eael-ext-scroll-to-top-button {
  position:fixed;
  cursor:pointer;
  display:-webkit-box;
  display:-ms-flexbox;
  display:flex;
  -webkit-box-align:center;
  -ms-flex-align:center;
  align-items:center;
  -webkit-box-pack:center;
  -ms-flex-pack:center;
  justify-content:center
 }
 

// Footer
.footer {
  color: $white;
  font-size: .9rem;
  line-height: 1.4;
  position: relative;
  z-index:100;
  a {
    color: $white;
  }
  .footer-info {
    background-color: #4a4949;
    .hubpot-partner {
      max-width: 140px;
      @include media-breakpoint-up(lg) {
        max-width: 181px;
      }
    }
    .logo-white {
      max-width: 150px;
      @include media-breakpoint-up(lg) {
        max-width: 240px;
      }
    }
  }
  .footer-bottom {
    background-color: #393939;
  }
}

.widget-type-rss_listing {
  h3 {
    font-size: $h5-font-size;
    margin-bottom: .5rem;
  }
  .hs-rss-item {
    margin-bottom: 10px;
  }
}

// make position responsive
@each $breakpoint in map-keys($grid-breakpoints) {
  @include media-breakpoint-up($breakpoint) {
    $infix: breakpoint-infix($breakpoint, $grid-breakpoints);
    .position#{$infix}-static {
      position: static !important;
    }
    .position#{$infix}-relative {
      position: relative !important;
    }
    .position#{$infix}-absolute {
      position: absolute !important;
    }
    .position#{$infix}-fixed {
      position: fixed !important;
    }
    .position#{$infix}-sticky {
      position: -webkit-sticky !important;
      position: sticky !important;
    }

    .d#{$infix}-shadow {
      box-shadow: $box-shadow !important;
    }
    .d#{$infix}-shadow-sm {
      box-shadow: $box-shadow-sm !important;
    }
    .d#{$infix}-shadow-lg {
      box-shadow: $box-shadow-lg !important;
    }
    .d#{$infix}-shadow-none {
      box-shadow: none !important;
    }
  }
}

// WordPress, Elementor

//
// HubSpot
//
.inputs-list {
  @extend .list-unstyled;
  .hs-form-checkbox {
    margin-bottom: 10px;
    > label {
      .hs-form-underline &,
      .hs-form-transparent &  {
        color: $white;
      }
      .hs-form-underline-secondary &  {
        color: $secondary;
      }
      > input.hs-input {
        margin-right: 10px;
      }
      > span {
        font-size: $font-size-base;
      }
    }
  }
}

.hs-form-field {
  margin-bottom: 15px;
  > label {
    font-size: 13px;
    .hidden-label & {
      display: none;
    }
    .hs-form-white & {
      color: $white;
    }
  }
  .hs-input[type=text],
  .hs-input[type=tel],
  .hs-input[type=email],
  select.hs-input,
  textarea.hs-input {
    @extend .form-control;
    &::-webkit-input-placeholder { /* Edge */
      color: #d3d3d3;
    }
    &:-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: #d3d3d3;
    }
    &::placeholder {
      color: #d3d3d3;
    }
    // @extend .rounded;
    .hs-form-underline & {
      border-left: 0 !important;
      border-top: 0 !important;
      border-right: 0 !important;
      background-color: transparent !important;
      color: $white;
      -webkit-border-radius: 0;
      -moz-border-radius: 0;
      border-radius: 0;
       &:focus {
         border-color: $white;
         box-shadow: none;
       }
     }
     .hs-form-transparent & {
       border-left: 0 !important;
       border-top: 0 !important;
       border-right: 0 !important;
       border-bottom: 0 !important;
       background-color: rgba(255, 255, 255, .2);
       color: $white;
       &:focus {
         box-shadow: none;
       }
     }
     .hs-form-underline-secondary & {
       border-left: 0 !important;
       border-top: 0 !important;
       border-right: 0 !important;
       background-color: transparent !important;
       color: $secondary;
       border-color: $secondary;
       -webkit-border-radius: 0;
       -moz-border-radius: 0;
       border-radius: 0;
       &:focus {
         border-color: $primary;
         box-shadow: none;
       }
       &::-webkit-input-placeholder { /* Edge */
         color: #2f5a8f;
       }
       &:-ms-input-placeholder { /* Internet Explorer 10-11 */
         color: #2f5a8f;
       }
       &::placeholder {
         color: #2f5a8f;
       }
     }
     @include media-breakpoint-down(md) {
       width: 100% !important;
     }
  }
  .hs-error-msgs {
    @extend .list-unstyled;
    @extend small;
    margin-top: .5rem;
    .hs-form-underline &,
    .hs-form-transparent & {
      color: $white;
    }
    .hs-form-underline-secondary & {
      color: $secondary;
    }
  }
}

.legal-consent-container {
  font-size: 12px;
  line-height: 1.2;
  .hs-form-underline &,
  .hs-form-transparent &,
  .hs-form-underline-secondary &,
  .hs-form-white & {
    color: $white;
  }
  a {
    text-decoration: underline !important;
    .hs-form-white & {
      color: $white;
    }
  }
}

.hs-form-required {
  color: $danger;
}

.hs-error-msg {
  font-size: 13px;
}

.hs_recaptcha {
  margin-bottom: 20px;
}

.hs-button {
  @extend .btn;
  &.primary {
    @extend .btn-primary;
  }
  color: $white !important;
}

.submitted-message {
  font-size: $font-size-base;
  .hs-form-underline &,
  .hs-form-transparent &,
  .hs-form-underline-secondary & {
    color: $white;
  }
  @extend .alert;
  @extend .alert-success;
}

.hs-form-underline,
.hs-form-transparent,
.hs-form-underline-secondary {
  fieldset {
    max-width: 100% !important;
  }
}

fieldset.form-columns-1,
fieldset.form-columns-2,
fieldset.form-columns-3,
fieldset.form-columns-4 {
  @extend .row;
  @extend .gx-3;
  .hs-form-field {
    @include media-breakpoint-down(md) {
      width: 100% !important;
    }
  }
  .input {
    margin-right: 0 !important;
  }
}

.form-columns-1 {
  .hs-input:not([type="checkbox"]) {
    width: 100% !important;
  }
}

.form-columns-2 {
  .hs-form-field {
    @extend .col-md-6;
    @include media-breakpoint-up(md) {
      width: percentage(divide(6, 12)) !important;
    }
  }
}

.form-columns-3 {
  .hs-form-field {
    @extend .col-md-4;
    @include media-breakpoint-up(md) {
      width: percentage(divide(4, 12)) !important;
    }
  }
}

.form-columns-4 {
  .hs-form-field {
    @extend .col-3;
    @include media-breakpoint-up(md) {
      width: percentage(divide(3, 12)) !important;
    }
  }
}

.hs_submit {
  @extend .row;
  @extend .gx-3;
  margin: 0;
}


//
// Blog Default Listing
//
.listing-template-default, .post-template-default {
  .featured-image {
    height: 100px;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;
    @include media-breakpoint-up(lg) {
      height: 170px;
    }
  }
}

//
// Blog Default Details
//
.post-template-default {
  .anchor{
    position: relative;
    top: -78px;
  }
  .author {
    font-size: .8rem;
    color: $gray-600;
    border-bottom: 1px solid $gray-300;
    margin-bottom: 1rem;
  }
  .post-body {
    a {
      color: $primary;
    }
    h2 {
      margin-top: 2rem;
    }
  }
  .blog-subscribe {
    form {
      border: 1px solid $primary;
      .hs-main-font-element {
        > h3 {
          margin-bottom: 0;
          font-size: 1rem;
          font-weight: 600;
          background-color: $primary;
          color: $white !important;
          text-align: center !important;
          font-weight: 600;
          padding: 1rem;
        }
      }
      .hs-fieldtype-text {
        margin-left: 1rem;
        margin-right: 1rem;
      }
      .legal-consent-container {
        > div {
          padding-left: 1rem;
          padding-right: 1rem;
          font-size: $font-size-base;
          &:last-child {
            background-color: $primary;
            color: $white;
            padding-top: 1rem;
            padding-bottom: 1rem;
            p {
              font-size: .8rem !important;
              margin-bottom: 0 !important;
              a {
                color: $white;
              }
            }
          }
        }
      }
      .hs_submit {
        background-color: $primary;
        padding: 0 1rem 1rem !important;
        .actions {
          padding-left: 0;
          padding-right: 0;
          .hs-button.primary {
            border-color: $white !important;
            width: 100% !important;
          }
        }
      }
      .hs-error-msg {
        color: $danger;
      }
    }
  }
}