@mixin bsBanner($file) {
/* 
Theme Name: Hello Elementor Child
Theme URI: https://github.com/elementor/hello-theme/
Description: Hello Elementor Child is a child theme of Hello Elementor, created by Elementor team
Author: Elementor Team
Author URI: https://elementor.com/
Template: hello-elementor
Version: 1.0.1
Text Domain: hello-elementor-child
License: GNU General Public License v3 or later.
License URI: https://www.gnu.org/licenses/gpl-3.0.html
Tags: flexible-header, custom-colors, custom-menu, custom-logo, editor-style, featured-images, rtl-language-support, threaded-comments, translation-ready
*/
  /*!
   * Bootstrap #{$file} v5.3.0 (https://getbootstrap.com/)
   * Copyright 2011-2023 The Bootstrap Authors
   * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
   */
}
