//== Colors
//
//## Brand colors to use across Wave

$gray-100: #f8f9fa !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #6c757d !default;
$gray-700: #495057 !default;
$gray-800: #333333 !default;
$gray-900: #212529 !default;
$black:    #000 !default;
$white:    #fff !default;
 
$gray:              #54595f !default; // primary
$red:               #f0283d !default; // secondary
$amber:            #ffc400 !default; // accent